import qs from 'qs';
import { instance as api } from '@/api/api';
import { getUrl } from '@/api/utils';

export const actionUrl = (migrationId, action, queryParams = {}) => getUrl(`/migration/${migrationId}/${action}`, queryParams);

function GET(migrationId, action, queryParams, config) {
    return api.get(actionUrl(migrationId, action, queryParams), config);
}

function POST(migrationId, action, data, config) {
    return api.post(actionUrl(migrationId, action), qs.stringify(data), config);
}

export async function migrationRename(migrationId, name) {
    return POST(migrationId, 'rename', { name });
}

export async function migrationEntitiesRefresh(migrationId) {
    return GET(migrationId, 'entities-refresh');
}

export async function migrationEntityAdd(migrationId, sourceId, targetId) {
    return POST(migrationId, 'entity/add', {
        source: sourceId,
        target: targetId,
    });
}

export async function migrationEntityGet(migrationId, entityId) {
    return GET(migrationId, `entity/${entityId}/get`);
}

export async function migrationEntityToggle(migrationId, entityId, value) {
    return POST(migrationId, `entity/${entityId}/toggle`, {
        value,
    });
}

export async function migrationEntityChangeTarget(migrationId, entityId, targetId) {
    return POST(migrationId, `entity/${entityId}/change-target`, {
        target: targetId,
    });
}

export async function migrationEntityFilterGet(migrationId, entityId) {
    return GET(migrationId, `entity/${entityId}/filter/get`);
}

export async function migrationEntityFilterSave(migrationId, entityId, filter) {
    return POST(migrationId, `entity/${entityId}/filter/save`, {
        filter: JSON.stringify(filter),
    });
}

export async function migrationEntityPriorityGet(migrationId) {
    return GET(migrationId, 'entity-priority-get');
}

export async function migrationEntityPrioritySave(migrationId, priority) {
    return POST(migrationId, 'entity-priority-save', {
        priority: JSON.stringify(priority),
    });
}

export async function migrationEntityRelationPriorityGet(migrationId, entityId) {
    return GET(migrationId, `entity/${entityId}/relation-priority-get`);
}

export async function migrationEntityRelationPrioritySave(migrationId, entityId, priority) {
    return POST(migrationId, `entity/${entityId}/relation-priority-save`, {
        priority: JSON.stringify(priority),
    });
}

export async function migrationEntitySetStrategies(migrationId, entityId, strategies) {
    return POST(migrationId, `entity/${entityId}/set-strategies`, {
        strategies,
    });
}

export async function migrationInstanceSettingsGet(migrationId, { type, instanceId, preset }) {
    return POST(migrationId, 'instance/settings/get', {
        type,
        instanceId,
        preset,
    });
}

export async function migrationImportantRecordsList(migrationId) {
    return GET(migrationId, 'important-records/list');
}

export async function migrationImportantRecordsFind(migrationId, link) {
    return POST(migrationId, 'important-records/find-by-url', {
        link,
    });
}

export async function migrationImportantRecordsSave(migrationId, records) {
    return POST(migrationId, 'important-records/add', {
        records,
    });
}

export async function migrationImportantRecordsRemoveAll(migrationId, entities) {
    return POST(migrationId, 'important-records/remove-all', {
        entities,
    });
}

export async function migrationManagerInstanceCreate(migrationId, { instance, entities, priority, note }) {
    return api.post('/manager/migration/create-instance', qs.stringify({
        migrationId,
        note,
        instance: JSON.stringify(instance),
        entities: JSON.stringify(entities),
        priority: JSON.stringify(priority),
    }));
}

export async function migrationPricingOptions(migrationId) {
    return GET(migrationId, 'pricing/options');
}

export async function migrationPricingBuy(migrationId, { coupon, license }) {
    return GET(migrationId, 'pricing/buy', { coupon, license });
}
